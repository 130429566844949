import sass from "../scss/main.scss";
import '@fortawesome/fontawesome-free/js/all';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import '@fancyapps/fancybox/dist/jquery.fancybox';


$(document).ready(function () {


  // Попап
  $('.fancybox').fancybox({
    btnTpl: {
      smallBtn:
        '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
        "</button>"
    }
  });

  // Скролл к якорю
  if ($('.scroll').length) {
    $(".scroll").on('click', function () {
      var target = $(this).attr('href');
      // animate
      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, 1000);

      return false;

    });
  }

  // Слайдер отзывов
  $(".testimonials__slider").owlCarousel({
    dots: false,
    items: 1,
    loop: true,
    autoplay: true,
    nav: true,
    autoplayTimeout: 8000,
    animateOut: 'fadeOut',
    navText: ['','']
  });


});
